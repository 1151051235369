import React from "react";
import { ReactSVG } from "react-svg";
import donateBannerIcon from "@images/donate-banner-icon.svg";
import closeBannerIcon from "@images/close-banner-icon.svg";
import { BannerStyles } from "./BannerStyles";
import {Link} from "gatsby";

const Banner = ({ setIsOpenedBanner }) => (
    <>
        <BannerStyles />
        <div className="banner">
            <div className="banner-container">
                <div className="banner-top-container">
                    <ReactSVG
                        src={donateBannerIcon}
                        className="banner-donate-icon"
                        alt="Donate UA icon"
                    />
                    <ReactSVG
                        src={closeBannerIcon}
                        className="banner-close-btn"
                        onClick={() => setIsOpenedBanner(false)}
                    />
                </div>
                <div className="banner-text-container">
                    <span className="banner-text banner-text-gold">
                        Support Ukraine
                    </span>
                    <br />
                    <span className="banner-text">
                        in it’s Fight for
                        <br />
                        Peace in Europe
                    </span>
                </div>
            </div>
            <div className="banner-text-link-container">
                <Link
                    target="_blank"
                    to="https://uahelp.monobank.ua/"
                    className="banner-text-link"
                    rel="nofollow noreferrer"
                >
                    Donate
                </Link>
                <span />
                <Link
                    target="_blank"
                    to="https://supportukrainenow.org/"
                    className="banner-text-link"
                    rel="nofollow noreferrer"
                >
                    Other ways to help
                </Link>
            </div>
        </div>
    </>
);

export default Banner;
