import React, { useState } from "react";
import Banner from "./Components/Banner/Banner";
import BannerButton from "./Components/BannerButton/BannerButton";

const DonateBanner = () => {
    const [isOpenedBanner, setIsOpenedBanner] = useState(false);

    return isOpenedBanner ? (
        <Banner setIsOpenedBanner={setIsOpenedBanner} />
    ) : (
        <BannerButton setIsOpenedBanner={setIsOpenedBanner} />
    );
};

export default DonateBanner;
