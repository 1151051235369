import { createGlobalStyle } from "styled-components";
import { MOBILE_MIN_WIDTH } from "@constants";

export const BannerButtonStyles = createGlobalStyle`
  .donate-btn {
    position: relative;
    z-index: 100;

    &-image {
      width: 2.12rem;
      height: 2.12rem;
      position: fixed;
      bottom: 3.125rem;
      right: 7.125rem;
      z-index: 100;
      background-color: var(--dark-purple);
      border: 0.0625rem solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
      padding: 0.4375rem;

      svg {
        width: 2.12rem;
        height: 2.12rem;
        z-index: 100;
      }

      &:hover {
        border: 0.0625rem solid white;
        background: #3A1A3C;
        cursor: pointer;
      }

      @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        bottom: 1.5625rem;
        right: 5.625rem;
      }
    }
  }
`;
