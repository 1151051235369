import { createGlobalStyle } from "styled-components";
import { MOBILE_MIN_WIDTH } from "@constants";

export const BannerStyles = createGlobalStyle`
  .banner {
    position: fixed;
    bottom: 3.125rem;
    right: 7.125rem;
    width: 20rem;
    height: fit-content;
    min-height: 16.25rem;
    z-index: 100;
    background-color: var(--dark-purple);

    &-text-link {
      position: relative;
      width: fit-content;
      text-decoration: none;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: #c4b180;
      transition: text-decoration 300ms linear;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 0.125rem;
        bottom: 0;
        left: 0;
        background-color: #c4b180;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
          display: none;
        }
      }

      &:hover {
        &:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
        
        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
          transform: none;
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        row-gap: 0.5rem;
        background-color: var(--dark-purple);
        padding: 0.625rem 1.5rem;
        border: 0.0625rem solid rgba(255, 255, 255, 0.2);

        & span {
          display: block;
          background: rgba(255, 255, 255, 0.2);
          height: 0.0625rem;
          width: 100%;
        }
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
      border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
      border-left: 0.0625rem solid rgba(255, 255, 255, 0.2);
    }

    &-text {
      font-size: 1.5rem;
      line-height: 2rem;

      &-gold {
        color: #C4B180;
      }

      &-container {
        padding: 0 1rem 1rem 1.5rem;
      }
    }

    &-top-container {
      display: inherit;
      justify-content: space-between;
      padding: 1rem 1rem 0.925rem 1rem;
    }

    &-close-btn {
      width: 2.25rem;
      height: 2.25rem;
      border: 0.0625rem solid rgba(255, 255, 255, 0.2);

      &:hover {
        border: 0.0625rem solid white;
        background: #3A1A3C;
        cursor: pointer;
      }

      svg {
        height: 0.75rem;
        width: 0.75rem;
        padding: 0.75rem;
      }
    }

    &-donate {
      &-icon {
        svg {
          width: 4rem;
          height: 3.875rem;
        }
      }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      bottom: 1.5625rem;
      right: 1.5rem;
      z-index: 101;

      &-donate-section-opened {
        overflow: scroll;
      }
    }
  }
`;
